.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  /* color: rgba(255, 255, 255, 0.87); */
  
}
 
.card {
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem; 
  box-shadow: none;
}

/* .card.flex.justify-content-center {
  margin-bottom: 0rem;
  padding: 0rem;
} */
/* 
.banner-gigs{
  padding: 0px;
  margin-top: 0px;
} */
.logo-img {
  width: 56px;
  height: 54px;
  margin: -17px;
}

/* .body {
  font-family: var(--font-family);
  font-weight: normal;
  background: var(--surface-ground);
  color: var(--text-color);
  padding: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
.card.flex.justify-content-center {
  border: none;
  box-shadow: none;
}
 
#my-new-side-bar {
	position: fixed;
	overflow: none;
	left: 50px;
  height: auto;
	text-align: center;
	top: 100px;
	width: 190px;
	background: #F1F9F6;
/* 	background: #F0F0F1; */
/* 	background: none; */
/* 	color: #38305F;
	box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%); */
  border: none;
	border-radius: 4px;
/* 	color: #38305F; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	outline: none;
	padding: .34rem .75rem;
} 
#btnScrollToTop {
  position: fixed;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%);
  bottom: 511px;
  left: 22px;
  /* bottom: 30px; */
  height: 50px;
}
.gigs-divBox {
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: none;
}
.gigs-megamenu {
  padding: 0.5rem;
  /* background: #f8f9fa; */
  color: #495057;
  border: 1px solid #dee2e6;
  /* border-radius: 6px; */
}
@media screen and (max-width: 575px) {

  .layout-wrapper .layout-content .layout-content-inner,
  .layout-wrapper .layout-footer,
  .layout-wrapper .layout-topbar-inner {
    padding-right: 1rem;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*  */

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.body {
  margin: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  line-height: 24px;
  font-family: var(--vp-font-family-base);
  font-size: 16px;
  font-weight: 400;
  color: var(--vp-c-text-1);
  background-color: var(--vp-c-bg);
  direction: ltr;
  font-synthesis: style;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/*  */

body {
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.5;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

p {
  font-size: 20px;
}

.LoginDiv {
  margin: 0, auto;
  padding-top: 212px;
  justify-content: center;
  max-width: 2022px;
}

.RegisterDiv {
  margin: 0, auto;
  padding-top: 212px;
  justify-content: center;
  max-width: 2022px;
}

.yaseen {
  margin: 0, auto;
  justify-content: center;
  max-width: 2022px;

}

.row {
  align-items: center;
  min-height: 100px;
  width: 100%;
  flex-direction: column;
  justify-content: center;

}

form i {
  margin-left: -30px;
  cursor: pointer;
}

.my-0 {
  width: 10%;
}

.img2 {
  width: 10%;
  height: auto;
}

.section {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-main {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  max-width: 80%;
  margin: auto;
  height: 80%;
}

.secondhalf {
  width: 30%;
}

.img1 {
  width: 35%;
  height: auto;
  position: absolute;
  top: 200px;
  right: 240px;
  left: 500px;
  overflow: hidden;
}


.loginImge {
  width: 35%;
  height: auto;
  position: fixed;
  top: 260px;
  right: 207px;
  left: 145px;
  overflow: hidden;
}

.loginImge2 {
  width: 35%;
  height: auto;
  position: fixed;
  top: 503px;
  right: 54px;
  left: 1000px;
  overflow: hidden;
}


.wrap {
  height: 200%;
  display: flex;
  width: 1000px;
  padding-top: 20px;
  box-sizing: content-box;
  max-width: fit-content;
}

.button {
  width: 110px;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(235, 225, 225, 0.1);
  transition: all .5s ease-in-out;
  cursor: pointer;
  outline: none;
}

.button:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: scale(1.1);
}

.button3 {
  width: 217px;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(235, 225, 225, 0.1);
  transition: all .5s ease-in-out;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 630px;
  /* top: 1000px; */
  left: 854px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: -6px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ps-sidebar-container .css-dip3t8 {
  position: relative;
  height: 100vh;
  /* updated */
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: rgb(240 240 240 / 70%);
}

/* styles for screens smaller than 768px */
@media (max-width: 767px) {
  .d-flex.flex-column.flex-md-row.align-items-center.p-3.px-md-4.mb-3.bg-white.border-bottom.box-shadow {
    /* styles for small screens here */
  }
}

/* styles for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .d-flex.flex-column.flex-md-row.align-items-center.p-3.px-md-4.mb-3.bg-white.border-bottom.box-shadow {
    /* styles for medium screens here */
  }
}

/* styles for screens larger than 992px */
@media (min-width: 992px) {
  .d-flex.flex-column.flex-md-row.align-items-center.p-3.px-md-4.mb-3.bg-white.border-bottom.box-shadow {
    /* styles for large screens here */
  }
}

.button3:hover {
  background-color: #2EE59D;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: scale(1.1);
}

.button1 {
  width: 110px;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  background-color: #2EE59D;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(235, 225, 225, 0.1);
  transition: all .5s ease-in-out;
  cursor: pointer;
  outline: none;
}

.button1:hover {
  background-color: blue;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: scale(1.1);
}


.button2 {
  width: 110px;
  height: 35px;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  color: white;
  background-color: #2EE59D;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(235, 225, 225, 0.1);
  transition: all .5s ease-in-out;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 240px;
  right: 22px;
  left: 700px;
}

.button2:hover {
  background-color: blue;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: scale(1.1);
}

input {
  display: flex;
  padding: 10px;
  border: 0;
  size: 33px;
  box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
}

/* .bg {
  background-image: url("./pages/static/images/image2.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
} */



/* to remove */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  /* background-color: #fffdf2; */
}

::-webkit-scrollbar-thumb {
  background-color: #335ddc;
  border-radius: 8px;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffff;
}

.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 12px;
}

#login-form {
  flex: 1 0 100%;
  max-width: 480px;
  width: 100%;
  padding: 60px;
}

#login-form p {
  margin-bottom: 30px;
}

#login-form p.form-title {
  color: #333333;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
}

#login-form .ant-form-item-label>label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-control-input-content {
  text-align: left;
}

#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}

#login-form_username {
  height: 48px;
}

#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.login-form-button {
  width: 100%;
}

.illustration-wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }

  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }

  #login-form {
    max-width: 100%;
  }
}

.login-page input {
  box-shadow: none;
}

/* to remove */